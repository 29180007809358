.selectMain {
  width: 100%;
  margin-right: 25px;
  cursor: pointer;
  &_button {
    border: 1px solid $color_primary_main;
    border-right: none;
    color: $color_primary_main;
    border-radius: 3px 0 0 3px;
    padding: 6px 12px;
    cursor: pointer;
    background-color: $color_neitral_white;
    transition: all .3s;
    font-size: 14px;
    @media screen and ( max-width: $weight_mobile ) {
      padding: 0 12px; }
    &:hover {
      background-color: $color_primary_second;
      border: 1px solid $color_primary_second;
      color: $color_neitral_white; }
    &:active {
      background-color: $color_secondary_thirth;
      border: 1px solid $color_secondary_thirth;
      color: $color_neitral_white; } }
  &_normal {
    & div {
      & .css-yk16xz-control, & .css-1pahdxg-control, &  .css-1fhf3k1-control {
        min-height: $height_desktop !important;
        @media screen and ( max-width: 940px ) {
          min-height: $height_tablet !important; }
        @media screen and ( max-width: $weight_mobile ) {
          min-height: $height_mobile !important; } } } }
  &_small div .css-yk16xz-control, &_normal div .css-1pahdxg-control {
      min-height: 35px; }
  @media screen and ( max-height: 800px ) {
    padding-bottom: 0; } }


div>.css-1wa3eu0-placeholder {
  color: $color_neitral_lightgray; }


.css-xb97g8 {
  background: $color_neitral_white;
  border: 1px solid $color_primary_main;
  border-radius: 5px;
  color: $color_primary_main;
  cursor: pointer;
  transition: all .3s; }


.css-6q0nyr-Svg {
  cursor: pointer; }

.table_pagination-select select {
  background-color: white; }
