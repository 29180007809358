.mainDatePicker {
  width: 100%;
  position: relative;
  &_container {
    background: transparent;
    position: relative;
    width: 100%;
    padding-left: 20px;
    border-radius: 4px;
    font-size: 15px;
    border: 1px solid $color_neitral_lightgray;
    height: 48px;
    @media screen and ( max-width: $weight_tablet ) {
      height: 38px; }
    @media screen and ( max-width: $weight_mobile ) {
      height: 33px; }
    & input {
      width: 100%; } }
  &_clear {
    height: 25px;
    position: absolute;
    top: 50%;
    transition: all .3s;
    transform: translateY(-50%);
    right: 50px;
    fill: $color_neitral_gray;
    &--enable {
      cursor: pointer;
      &:hover {
        fill: $color_secondary_seventh; }
      &:active {
        fill: $color_secondary_thirth; } }
    &--disabled {
      opacity: .6;
      cursor: not-allowed; } }
  & .react-datepicker-wrapper {
    width: 100%;
    & div input {
      width: 100%; } }
  &--small {
    & div div input {
      height: 35px; } } }

.react-datepicker-popper {
  position: relative;
  z-index: 2; }


span.rw-select:hover {
  background-color: #fff; }

.rw-btn-select {
  opacity: 1;
  color: $color_neitral_gray; }

button.rw-btn.rw-btn-select {
  transition: all .3s;
  &:hover {
    color: $color_secondary_seventh; }
  &:active {
    color: $color_secondary_thirth; } }

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: $color_neitral_gray;
  font-size: 26px;
  font-weight: 600;
  padding: 0;
  padding-right: 5px; }
