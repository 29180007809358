.sideApps {
  display: flex;
  @media screen and ( max-height: 720px ) {
    margin-bottom: 1vh; }
  & img {
    height: 35px; }
  &_column {
    flex-direction: column; }
  &_row {
    flex-direction: row;
    & img {
      margin-right: 20px; } } }
