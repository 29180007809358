.mainMenu {
  position: relative;
  height: 70px;
  width: 100%;
  border-bottom: 1px lighten(gray, 40) solid;
  background-color: $color_neitral_background;
  background-image: url('../../sources/images/header_background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 130px;
  @media screen and ( max-width: 630px ) {
    background-color: $color_neitral_white; }
  &--mobile {
    display: none;
    &_logo {
      font-size: 24px;
      font-weight: 900;
      color: $color_primary_main; }
    &_username {
      font-size: 20px; }
    @media screen and ( max-width: 700px ) {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center; } }
  &--desktop {
    display: flex;
    align-items: center;
    @media screen and ( max-width: 700px ) {
      display: none; } }
  @media screen and ( max-width: 1024px ) {
    padding: 0 70px; }
  @media screen and ( max-width: 630px ) {
    padding: 0; }
  &-red {
    color: $color_secondary_sixth; }
  &_item {
    margin-right: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:nth-last-child(2) {
      margin-right: 0; } }
  &-ball {
    fill: $color_primary_main; }
  &_logo {
    &-title {
      margin-right: 10px;
      font-size: 14px;
      color: $color_neitral_lightDark;
      &-company {
        font-size: 12px;
        font-weight: 600;
        color: $color_secondary_thirth; } }
    &-chevron {
      fill: $color_neitral_lightDark;
      transition: all .3s;
      &--active {
        transform: rotate(180deg); } }
    &-menu {
      width: 200px;
      background-color: white;
      position: absolute;
      border-radius: 10px;
      right: 120px;
      box-shadow: 0px 4px 20px rgba(21, 10, 39, 0.15);
      transition: all .3s;
      padding: 15px;
      @media screen and ( max-width: 1024px ) {
        right: 70px; }
      &--disabled {
        opacity: 0;
        top: -60px; }
      &--active {
        opacity: 1;
        top: 80px;
        z-index: 2; }
      &--item {
        width: 100%;
        display: flex;
        justify-content: center;
        transition: all .3s;
        cursor: pointer;
        &:hover {
          color: $color_primary_second; }
        &:active {
          color: $color_secondary_thirth; } } } } }

