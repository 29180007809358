.mobileMenuList {
  position: absolute;
  height: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all .3s;
  background-color: #ffffff;
  z-index: 4;
  &>ul {
    position: absolute;
    top: -400px; }
  &_link {
    text-decoration: none;
    color: $color_primary_main;
    transition: all .3s;
    &-red {
      color: $color_secondary_sixth; }
    &--active {
      &:hover {
        color: $color_primary_second; }
      &:active {
        color: $color_secondary_thirth; } } }
  &--active {
    height: calc( 100vh - 70px );
    opacity: 1; }
  &>ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    &>li {
      margin-bottom: 20px;
      font-size: 24px; } }
  &--active>ul {
    position: relative;
    top: auto; } }


