.statusViwer {
  cursor: pointer;
  display: flex;
  align-items: center;
  & .viwerCircle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-right: 10px;
    &--awaiting {
      background-color: $color_secondary_fourth; }
    &--confirmed {
      background-color: $color_secondary_second; }
    &--inWork {
      background-color: $color_neitral_lightDark; }
    &--completed {
      background-color: $color_primary_second; }
    &--paid {
      background-color: $color_secondary_seventh; }
    &--canceled {
      background-color: $color_neitral_gray; }
    &--oldest {
      background-color: $color_neitral_gray; }
    &--error {
      background-color: $color_secondary_sixth; } } }
