.createOrder {
  position: relative;
  padding: 40px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color_neitral_background;
  &_center {
    display: flex;
    align-items: center;
    justify-content: center; }
  @media screen and ( max-width: 630px ) {
    padding: 30px 0; }
  &_multiSelect {
    width: 93%;
    margin-right: 25px;
    cursor: pointer;
    position: relative; }
  @media screen and ( max-height: 800px ) {
    padding: 20px;
    padding-bottom: 0; }
  &_leftInput {
    margin-right: 30px;
    @media screen and ( max-width: 940px ) {
      margin-right: 20px; } }
  &_input {
    width: 100%; }
  &_button {
    width: 100%;
    display: flex;
    justify-content: center;
    & > button {
      width: 200px; } }
  &>div {
    max-width: 1240px;
    width: 100%; }
  &_info {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    &-man {
      display: flex;
      margin-right: 80px;
      @media screen and ( max-width: 630px ) {
        margin-right: 20px; }
      & figure {
        width: 70px;
        height: 70px;
        overflow: hidden;
        margin: 0;
        border-radius: 100%;
        margin-right: 30px;
        @media screen and ( max-width: 630px ) {
          margin-right: 10px; }
        & img {
          display: block;
          width: 100%; } }
      & div {
        & h3 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
          @media screen and ( max-width: 630px ) {
            margin-bottom: 5px; } }
        & span {
          font-size: 18px; } } }
    &-status {
      & h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        @media screen and ( max-width: 630px ) {
          margin-bottom: 5px; } }
      & div {
        display: flex;
        align-items: center; } } }
  &_title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;
    @media screen and ( max-width: 630px ) {
      flex-direction: column;
      align-items: center; }
    &-date {
      font-size: 12px;
      color: $color_neitral_gray;
      @media screen and ( max-width: 450px ) {
        font-size: 10px; } }
    &--step {
      width: 120px;
      margin-right: 45px;
      margin-bottom: -5px;
      @media screen and ( max-width: 450px ) {
        margin-right: 00px; }
      & .fa-walking {
        margin-right: 8px;
        font-size: 22px;
        color: $color_neitral_gray;
        transform: translateY(-2px); }
      &>svg {
        margin-bottom: -1px;
        margin-right: 8px; }
      &>span {
        color: $color_neitral_gray;
        font-size: 22px;
        line-height: 26px; } }
    &--description {
      color: $color_neitral_gray;
      font-size: 14px;
      margin-bottom: -4px; }
    &-left {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @media screen and ( max-width: 500px ) {
        margin-bottom: 15px;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 20px; }
      &--monoLine {
        display: flex;
        align-items: flex-end;
        @media screen and ( max-width: 500px ) {
          width: 100%; } }
      &>h2 {
        color: $color_neitral_dark;
        line-height: 20px;
        font-size: 24px;
        margin-right: 45px;
        @media screen and ( max-width: 450px ) {
          margin-right: 20px;
          font-size: 20px; } } }
    &-right {
      display: flex; } }
  &_form {
    background-color: #fff;
    padding: 40px;
    width: 100%;
    position: relative;
    transition: all .3s;
    min-height: 500px;
    &-secondStepItem {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 50px;
      @media screen and ( max-height: 840px ) {
        margin-bottom: 30px; }
      & > select {
        width: 93%; } }
    & select {
      margin-right: 25px; }
    @media screen and ( max-width: 940px ) {
      padding: 20px; }
    &-item {
      width: 100%;
      margin-bottom: 45px;
      @media screen and ( max-height: 840px ) {
        margin-bottom: 30px; }
      &--title {
        display: flex;
        margin-bottom: 25px;
        & i {
          font-size: 22px;
          margin-right: 10px;
          &.red {
            color: $color_secondary_first; }
          &.blue {
            color: $color_secondary_second; }
          &.purple {
            color: $color_secondary_thirth; }
          &.yellow {
            color: $color_secondary_fourth; } }
        &>svg {
          margin-right: 10px; }
        &>h3 {
          font-weight: 400; } }
      &--content {
        display: flex;
        justify-content: space-between;
        @media screen and ( max-width: $weight_mobile ) {
          flex-direction: column; }
        &_partFirst {
          display: flex;
          width: 42%;
          @media screen and ( max-width: $weight_tablet) {
            width: 50%; }
          @media screen and ( max-width: $weight_mobile ) {
            width: 100%;
            margin-bottom: 20px; } }
        &_partSecond {
          width: 55%;
          @media screen and ( max-width: $weight_tablet ) {
            width: 47%; }
          @media screen and ( max-width: $weight_mobile ) {
            width: 100%; }
          & select {
            padding-left: 10px;
            height: 50px;
            width: 100%;
            border-radius: 5px;
            @media screen and ( max-width: $weight_tablet ) {
              height: 40px; } } } } }
    &-amount {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 50px;
      @media screen and ( max-width: $weight_mobile ) {
        flex-direction: column; }
      &-partFirst {
        width: 42%;
        &_content {
          display: flex;
          width: 100%; }
        @media screen and ( max-width: $weight_tablet ) {
          width: 50%; }
        @media screen and ( max-width: $weight_mobile ) {
          width: 100%;
          margin-bottom: 45px; } }
      &-PartSecond {
        width: 55%;
        &_content {
          display: flex;
          width: 100%; }
        @media screen and ( max-width: $weight_tablet ) {
          width: 47%; }
        @media screen and ( max-width: $weight_mobile ) {
          width: 100%; } } } }
  &_secondStep {
    &-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      &-button {
        width: 200px;
        &:first-child {
          margin-right: 30px; } } } } }

.createOrder_form-item--content_partSecond>.selectMain {
  @media screen and ( max-width: 630px ) {
    padding: 0; } }
