.creatableSelect {
  position: relative;
  &_itemsContainer {
    width: 100%;
    position: absolute;
    max-height: 300px;
    z-index: 3;
    overflow: auto;
    top: 60px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 10px 40px rgba(35, 15, 45, 0.15);
    transition: all .3s;
    &_selected {
      background-color: lighten($color_primary_second, 20); }
    &--hidden {
      transform-origin: top;
      transform: scale(0); }
    & ul {
      & li {
        padding: 10px;
        padding-left: 20px;
        cursor: pointer;
        font-size: 16px;
        &:first-child {
          padding-top: 15px; }
        @media screen and ( max-width: 630px ) {
          font-size: 14px; }
        &:hover {
          background-color: $color_primary_main;
          color: #fff; } } } } }
