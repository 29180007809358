.formNewMember {
  transition: all .3s;
  background-color: $color_neitral_white;
  border-radius: 10px;
  border: 1px solid $color_primary_main;
  padding: 40px 60px;
  & h3 {
    margin-bottom: 20px; }
  &_phone {
    display: flex;
    *:first-child {
      margin-right: 20px; } }
  &_input {
    margin-bottom: 20px;
    min-width: 300px; }
  &-background {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .3s;
    width: 100%;
    height: 0%;
    top: -400px; }
  &--active {
    top: 0;
    z-index: 4;
    transition: all .3s;
    opacity: 1;
    height: 100%;
    background-color: white; }
  &_buttons {
    transition: all .3s;
    display: flex; }
  &_button {
    margin-right: 20px; } }
