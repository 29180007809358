.infoPage {
  position: relative;
  padding: 40px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color_neitral_background;
  height: 100%;
  & div {
    height: 100%;
    width: 100%; }
  &_container {
    background-color: #fff;
    padding: 40px;
    width: 100%;
    height: 100%;
    position: relative;
    transition: all .3s;
    min-height: 500px; } }
