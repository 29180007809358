.titleIcon {
  display: flex;
  align-items: center;
  &_icon {}
  &_main {
    color: $color_primary_main; }
  &_second {
    color: $color_primary_second; }
  &_first {
    color: $color_secondary_first; }
  &_second {
    color: $color_secondary_second; }
  &_thirth {
    color: $color_secondary_thirth; }
  &_fourth {
    color: $color_secondary_fourth; }
  &_fivth {
    color: $color_secondary_fifth; }
  &_sixth {
    color: $color_secondary_sixth; }
  &_dark {
    color: $color_neitral_dark; }
  &_lightdark {
    color: $color_neitral_lightDark; }
  &_gray {
    color: $color_neitral_gray; }
  &_lightgray {
    color: $color_neitral_lightgray; }
  &_ultralightgray {
    color: $color_neitral_ultralightgray; }
  &_background {
    color: $color_neitral_background; }
  &_white {
    color: $color_neitral_white; } }
