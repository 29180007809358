.dashBoardBar {
  position: relative;
  width: 70px;
  background-color: $color_neitral_white;
  background-image: url('../../sources/images/sidebar_background.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 100vh;
  transition: all .3s;
  padding: 20px 17px;
  z-index: 5;
  @media screen and ( max-width: 700px ) {
    display: none; }
  &_link {
    text-decoration: none;
    color: $color_primary_main;
    transition: all .3s;
    font-size: 0; }
  &:hover {
    width: 230px;
    box-shadow: 0px 4px 40px rgba(35, 15, 45, 0.25);
    .dashBoardBar_logo-word {
      display: flex;
      font-size: 24px; }
    .dashBoardBar_icons-item {
      justify-content: start;
      align-items: center;
      text-decoration: none; }
    .dashBoardBar_link {
      font-size: 16px;
      margin-left: 10px;
      text-decoration: none; }
    .dashBoardBar_language-hide {
      transition: all .3;
      max-width: 100%;
      opacity: 1; } }
  &_logo {
    display: flex;
    align-items: center;
    & span {
      color: $color_neitral_dark;
      font-family: CeraPro; }
    &-word {
      transition: all .3s;
      font-size: 0; } }
  &_icons {
    &--canSelect:hover {
      .dashBoardBar_link {
        transition: all .3s;
        font-size: 16px;
        color: $color_primary_second; }
      .dashBoardBar_icons-item--icon {
        fill: $color_primary_second; } }
    &--disabled {
      .dashBoardBar_link {
        opacity: .5; }
      .dashBoardBar_icons-item--icon {
        opacity: .5; } }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;
      text-decoration: none;
      &--icon {
        fill: $color_primary_main;
        transition: all .3s; }
      &--active {
        .dashBoardBar_link {
          color: $color_neitral_lightDark; }
        .dashBoardBar_icons-item--icon {
          fill: $color_neitral_lightDark; } }
      &:active {
        .dashBoardBar_link {
          color: $color_secondary_thirth; }
        .dashBoardBar_icons-item--icon {
          color: $color_secondary_thirth; } }
      &:last-child {
        margin-bottom: 0; } } }
  &_language {
    display: flex;
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    &-icon {
      transition: all .3s;
      fill: $color_primary_main; }
    &:hover {
      .dashBoardBar_language-icon {
        fill: $color_primary_second; }
      .dashBoardBar_language-hide>span {
        color: $color_primary_second; } }
    &-hide {
      display: flex;
      transition: all .3s;
      align-items: center;
      max-width: 0px;
      opacity: 0;
      &>span {
        transition: all .3s;
        color: $color_primary_main;
        margin-right: 6px;
        margin-left: 15px; } } } }
