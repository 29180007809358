.logBar {
  display: flex;
  flex-direction: column;
  padding: 5vh 25px;
  width: 300px;
  background-color: $color_neitral_white;
  background-image: url('../../sources/images/sidebar_background.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 100vh;
  box-shadow: 5px 0px 40px rgba(35, 15, 45, 0.1);
  @media screen and ( max-height: 720px ) {
    padding: 4vh 25px; }
  &_logo {
    margin-bottom: 8vh;
    font-family: CeraPro;
    @media screen and ( max-height: 720px ) {
      margin-bottom: 2vh; } }
  &_button {
    margin-bottom: 4vh;
    @media screen and ( max-height: 720px ) {
      margin-bottom: 4vh; } }
  &_desription {
    font-size: 16px;
    line-height: 150%;
    color: $color_neitral_lightDark;
    margin-bottom: 35px;
    @media screen and ( max-height: 720px ) {
      margin-bottom: 20px; } }
  &_titleIcon {
    margin-bottom: 5px; }
  &_infoblock {
    margin-bottom: 30px;
    @media screen and ( max-height: 720px ) {
      margin-bottom: 20px; } }
  &_info {
    font-size: 18px;
    line-height: 170%;
    color: $color_neitral_lightDark; } }


