.orderWasCreated {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 700;
  color: $color_neitral_gray;
  padding-bottom: 120px;
  & h2 span {
    color: $color_primary_second; }
  & div {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center; }
  &_title {
    margin-bottom: 20px; }
  &_button {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    &:last-child {
      margin: 0; } }
  @media screen and ( max-width: $weight_tablet ) {
    font-size: 24px; } }

