.notFound {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 76px;
  font-weight: 700;
  color: $color_neitral_gray;
  padding-bottom: 120px;
  @media screen and ( max-width: $weight_tablet ) {
    font-size: 56px; } }

