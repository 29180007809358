.buttonCircle {
  border: 1px solid $color_primary_main;
  background-color: transparent;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .3s;
  & svg {
    fill: $color_primary_main; }
  &:hover {
    background-color: $color_primary_second;
    border: none;
    & svg {
      fill: $color_neitral_white; } }
  &:active {
    background-color: $color_secondary_thirth; }
  &_hover {
    &--green {
      background-color: $color_primary_second;
      border: none; }
    &--red {
      background-color: $color_secondary_first;
      border: none; } } }
