* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: Nunito;
	outline: none;
	text-decoration: none; }

li {
	list-style-type: none; }

.containerApp {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative; }

.containerContent {
	width: 100%;
	height: calc(100vh - 70px);
	padding-left: 70px;
	background-color: $color_neitral_background;
	@media screen and ( max-width: 630px ) {
		background-color: $color_neitral_white; }
	@media screen and ( max-width: 700px ) {
		padding: 0; } }

.mb1 {
	margin-bottom: 10px; }

.mb2 {
	margin-bottom: 20px; }

.mb3 {
	margin-bottom: 30px; }

.mb4 {
	margin-bottom: 40px; }

.mt1 {
	margin-top: 10px; }

.mt2 {
	margin-top: 20px; }

.mt3 {
	margin-top: 30px; }

.mt4 {
	margin-top: 40px; }

.mr1 {
	margin-right: 10px; }

.mr2 {
	margin-right: 20px; }

.mr3 {
	margin-right: 30px; }

.mr4 {
	margin-right: 40px; }

select {
	padding-left: 10px;
	height: 50px;
	width: 100%;
	border-radius: 5px;
	@media screen and ( max-width: 940px ) {
		height: 40px; } }

textarea {
	width: 100%;
	resize: none;
	border: 1px solid $color_neitral_lightgray;
	border-radius: 5px;
	padding: 20px;
	overflow: hidden; }

.disabled {
	opacity: .7;
	cursor: not-allowed !important;
	user-select: none;
	& * {
		user-select: none;
		cursor: not-allowed !important; } }

.none {
	display: none !important; }

.hidden {
	opacity: 0;
	transform: scale(0); }

.colorRed {
	color: $color_secondary_sixth; }

.desktopData {
	@media screen and ( max-width: 630px ) {
		display: none !important; } }

.mobileData {
	display: none !important;
	@media screen and ( max-width: 630px ) {
		display: flex !important; } }

div.MuiTooltip-tooltip {
	font-size: 12px; }
