.buttonIconUser {
  background-color: $color_primary_main;
  border: none;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  cursor: pointer;
  transition: all .3s;
  &--active {
    &:hover {
      background-color: $color_primary_second; }
    &:active {
      background-color: $color_secondary_thirth; } }
  @media screen and ( max-width: 630px ) {
    height: 38px;
    width: 38px;
    & svg {
      transform: scale(.8); } } }

.buttonBorderIcon {
  border: 1px solid $color_primary_main;
  background-color: #fff;
  padding-left: 0;
  & svg {
    height: 27px;
    fill: $color_primary_main;
    transition: all .3s; }
  &:hover {
    background-color: $color_primary_main;
    & svg {
      fill: #fff; } }
  &:active {
    border: none;
    background-color: $color_secondary_thirth;
    & svg {
      flll: #fff; } } }


