.orderList {
  padding: 40px;
  height: calc(100vh - 70px);
  position: relative;
  &_select {
    max-width: 250px; }
  &_picker {
    max-width: 250px; }
  &_loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center; }
  @media screen and ( max-width: 800px ) {
    padding: 20px;
    @media screen and ( max-width: 630px ) {
      padding: 0;
      padding-top: 20px; } }
  & .pageContent {
    background-color: $color_neitral_white;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &_title {
      display: flex;
      align-items: center;
      padding: 20px;
      &-filter {
        margin-right: 30px;
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;
        & svg {
          height: 25px;
          margin-right: 10px;
          cursor: pointer;
          fill: $color_secondary_seventh; }
        & span {
          font-size: 18px;
          cursor: pointer;
          color: $color_secondary_seventh; } }
      &-input {
        max-width: 250px;
        margin-right: 30px;
        &:last-child {
          margin-right: 0; } } } } }

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  &_rowWithIcon {
    padding: 0 10px;
    &:hover {
      .table_rowWithIcon_icon {
        color: $color_primary_second; } }
    &:active {
      .table_rowWithIcon_icon {
        color: $color_secondary_thirth; } }
    &_icon {
      font-size: 16px;
      color: $color_primary_main;
      transition: all .3s; } }
  &_triangle {
    height: 6px;
    transition: all .3s;
    &--active {
      fill: $color_neitral_gray; }
    &--disabled {
      fill: $color_neitral_ultralightgray; }
    &--desc {
      transition: all .3s;
      transform: rotate(180deg); } }
  & tbody tr {
    background-color: white;
    border-bottom: 1px solid $color_neitral_ultralightgray;
    cursor: pointer; }
  &_th, &_td {
    font-size: 13px;
    text-align: left;
    padding-right: 10px; }
  &_th {
    padding-bottom: 10px;
    height: 50px;
    & span {
      margin-right: 10px; }
    &:first-child {
      padding-left: 15px; } }
  &_td {
    padding-bottom: 9px;
    padding-top: 9px;
    height: 50px;
    &-colored {
      color: $color_neitral_lightDark; }
    &:first-child {
      padding-left: 15px; } }
  &_return_time {
    @media screen and ( max-width: 1600px ) {
      display: none; } }
  &_return_place {
    @media screen and ( max-width: 1500px ) {
      display: none; } }
  &_pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    @media screen and ( max-width: 630px ) {
      justify-content: start; }
    &>div {
      display: flex;
      align-items: center;
      &>svg {
        cursor: pointer;
        position: relative;
        transition: all .3s;
        border: none;
        & ellipse {
          opacity: 0;
          transition: all .3s; }
        path {
          fill: $color_neitral_gray; }
        line {
          stroke: $color_neitral_gray; }
        &:hover {
          transition: all .3s;
          & ellipse {
            transition: all .3s;
            opacity: 1;
            fill: $color_neitral_ultralightgray; } } }
      &:last-child {
        margin-right: 10px; } }
    &-currentPage, &-currentRows {
      margin-right: 20px; } }
  &_rowsBorder {
    &--awaiting {
      border-left: 2px solid $color_secondary_fourth; }
    &--confirmed {
      border-left: 2px solid  $color_secondary_second; }
    &--inWork {
      border-left: 2px solid  $color_neitral_lightDark; }
    &--completed {
      border-left: 2px solid  $color_primary_second; }
    &--paid {
      border-left: 2px solid  $color_secondary_seventh; }
    &--canceled {
      border-left: 2px solid  $color_neitral_gray; }
    &--oldest {
      border-left: 2px solid  $color_neitral_gray; }
    &--error {
      border-left: 2px solid  $color_secondary_sixth; } }
  &_rowsBackground {
    transition: all .3s;
    &--awaiting:hover {
      background-color: lighten( $color_secondary_fourth, 27); }
    &--confirmed:hover {
      background-color: lighten( $color_secondary_second, 44); }
    &--inWork:hover {
      background-color: lighten( $color_neitral_lightDark, 30); }
    &--completed:hover {
      background-color: lighten( $color_primary_second, 25); }
    &--paid:hover {
      background-color: lighten( $color_secondary_seventh, 60); }
    &--canceled:hover {
      background-color: lighten( $color_neitral_gray, 37); }
    &--oldest:hover {
      background-color: lighten( $color_neitral_gray, 37); }
    &--error:hover {
      background-color: lighten( $color_secondary_sixth, 35); } } }

.orderTrash {
  fill: $color_primary_main;
  cursor: pointer;
  transition: all 1s;
  &:hover {
    fill: $color_primary_second; }
  &:active {
    fill: $color_secondary_thirth; } }

.orderFilterList {
  z-index: 1;
  position: absolute;
  opacity: 0;
  left: -300px;
  transition: all .3s;
  height: calc(100vh - 110px);
  width: 50px;
  background-color: #fff;
  padding: 80px 20px;
  &-item {
    margin-bottom: 5px;
    &_description {
      display: inline-block;
      cursor: pointer;
      min-width: 110px;
      user-select: none; } }
  &--active {
    left: 38px;
    opacity: 1;
    width: 350px;
    box-shadow: 20px 20px 20px rgba(35, 15, 45, 0.15);
    @media screen and ( max-width: 800px ) {
      left: 18px; }
    @media screen and ( max-width: 630px ) {
      left: 0; } } }

.orderMobileTable {
  display: flex;
  flex-direction: column;
  &_btnUp {
    position: fixed;
    height: 50px;
    width: 50px;
    bottom: 30px;
    right: 30px;
    border: none;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(38, 35, 46, 0.2);
    background-color: $color_neitral_white;
    transition: all .3s;
    & svg {
      fill: $color_primary_second;
      height: 50%;
      transition: all .3s; }
    &:active {
      background-color: $color_neitral_ultralightgray;
      & svg {
        fill: $color_secondary_thirth; } } }
  &_item {
    width: 100%;
    border-bottom: 1px solid $color_neitral_ultralightgray;
    border-radius: 5px 5px 15px 15px;
    display: block;
    flex-direction: column;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .3s;
    padding: 20px 20px 0 20px;
    box-shadow: 0px 4px 10px rgba(38, 35, 46, 0.1);
    &-line {
      margin-bottom: 15px;
      transition: all .3s;
      &>span {
        transition: all .3s;
        font-weight: 300;
        font-size: 14px;
        margin-right: 10px; }
      &--hidden {
        max-height: 0px;
        margin: 0;
        &>span {
          font-size: 0px; } } }
    &:checked {
      background-color: #fff; }
    &-buttons {
      display: flex;
      & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        height: 40px;
        border: none;
        background-color: #fff;
        cursor: pointer;
        &.orderMobileTable_item-buttonLeft svg {
          fill: $color_secondary_fourth; }
        &.orderMobileTable_item-buttonCenter {
          border-left: 1px solid $color_neitral_ultralightgray;
          border-right: 1px solid $color_neitral_ultralightgray;
          & svg {
            fill: $color_secondary_first; } }
        &.orderMobileTable_item-buttonRight {
          fill: $color_secondary_thirth; } }
      & button svg {
        height: 20px; } } }
  &_contentItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 10px;
    &--first {
      padding-left: 10px; }
    & h3, & span {
      font-size: 14px;
      @media screen and ( max-width: 420px ) {
        font-size: 10px; } }
    & span {
      max-width: 200px;
      align-items: start; } } }

.table_th {
  cursor: pointer;
  &.table_manualnumber {
    width: 100px; } }
