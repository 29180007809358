.burgerMenu {
  position: relative;
  cursor: pointer;
  div {
    height: 2px;
    width: 30px;
    border-radius: 100px;
    background-color: black;
    margin-bottom: 5px;
    transition: all .3s;
    &:nth-child(3) {
      margin-bottom: 0; } }
  &--active {
    div {
      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px); }
      &:nth-child(2) {
        transform: rotate(160deg);
        opacity: 0; }
      &:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -5px); } } } }
