$color_primary_main : #7169FE;
$color_primary_second : #8CB489;

$color_secondary_first : #FF6596;
$color_secondary_second : #00A0FF;
$color_secondary_thirth : #A963FF;
$color_secondary_fourth : #FFC466;
$color_secondary_fifth : #7169FE;
$color_secondary_sixth : #D7365C;
$color_secondary_seventh : #166128;

$color_neitral_dark : #060D12;
$color_neitral_lightDark : #001B61;
$color_neitral_gray : #8C8F91;
$color_neitral_lightgray : #B5BDC1;
$color_neitral_ultralightgray : #DAE4EA;
$color_neitral_background : #F8FBFE;
$color_neitral_white : #fff;

$weight_tablet : 940px;
$weight_mobile : 630px;

$height_desktop : 50px;
$height_tablet : 40px;
$height_mobile : 35px;
