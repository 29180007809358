.smallInput {
  width: 100%;
  position: relative;
  &_clear {
    height: 25px;
    position: absolute;
    top: 50%;
    transition: all .3s;
    transform: translateY(-50%);
    right: 15px;
    fill: $color_neitral_gray;
    &--enable {
      cursor: pointer;
      &:hover {
        fill: $color_secondary_seventh; }
      &:active {
        fill: $color_secondary_thirth; } }
    &--disabled {
      opacity: .6;
      cursor: not-allowed; }
    @media screen and ( max-width: $weight_tablet ) {
      right: 15px;
      height: 20px; }
    @media screen and ( max-width: $weight_mobile ) {
      height: 18px;
      right: 10px; } }
  &>input {
    width: 100%;
    border: 1px solid $color_neitral_lightgray;
    border-radius: 4px;
    padding-left: 20px;
    outline: none;
    background-color: transparent;
    transition: all .3s;
    height: 37px;
    font-size: 16px;
    @media screen and ( max-width: $weight_tablet ) {
      height: 37px; }
    @media screen and ( max-width: $weight_mobile ) {
      height: 37px; }
    &:hover {
      border-color: lighten($color_primary_main, 20);
      box-shadow: 0px 0px 100px rgba(134, 115, 173, 0.12); }
    &:focus {
      border-color: lighten($color_primary_main, 15);
      box-shadow: 0px 0px 100px rgba(134, 115, 173, 0.13); }
    &:focus ~ label.smallInput_labelOn {
      top: 0;
      left: 13px;
      padding: 0 10px;
      background-color: #fff;
      color: lighten($color_primary_main, 20);
      font-size: 12px; }
    &:disabled {
      opacity: .3; } }
  &_small {
    & input {
      height: 37px;
      font-size: 13px; }
    & label {
      font-size: 13px; } }
  &>label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: $color_neitral_lightgray;
    transition: all .3s;
    font-size: 16px;
    @media screen and ( max-width: $weight_tablet ) {
      font-size: 14px; }
    @media screen and ( max-width: $weight_mobile ) {
      font-size: 14px; } }
  &_labelOn {}
  &_labelOff {
    display: none;
    opacity: 0;
    max-width: 0; }
  &_icon {
    &_off {
      display: none;
      opacity: 0;
      max-width: 0; }
    &_on {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      cursor: pointer;
      fill: $color_primary_second;
      transition: all .3s;
      &:hover {
        fill: $color_secondary_thirth; }
      &:active {
        fill: $color_primary_main; } } } }
label.smallInput_notEmpty {
  top: 0;
  left: 13px;
  padding: 0 10px;
  background-color: #fff;
  color: $color_neitral_lightgray;
  font-size: 12px; }
